<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Title</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(overview, index) in overviews.data"
                  :key="index"
                  v-if="overviews.data.length"
                >
                  <td class="align-middle text-center">
                    {{ overviews.from + index }}
                  </td>
                  <td>
                    <p class="mb-0">
                      <b>
                        {{ overview.title }}
                      </b>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        overview.status == 1 ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ overview.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(overview.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ overviews.from ? overviews.from : 0 }} to
                  {{ overviews.to ? overviews.to : 0 }} of
                  {{ overviews.total ? overviews.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="overviews"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="
            (form.id == '' ? 'Create' : 'Update') + ' Job Placement Overview'
          "
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="title"
                          placeholder="Enter Your Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <div class="d-block" style="height: 350px">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <quill-editor
                            id="description"
                            style="height: 220px"
                            class="mb-4"
                            v-model="form.description"
                            name="description"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Your Description"
                          />
                          <div style="clear: both"></div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-img
                        :src="image"
                        height="174"
                        width="174"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                      <b-form-group>
                        <b-form-file
                          class="mt-1"
                          id="image"
                          accept=".jpg, .png, .gif"
                          @change="loadImage($event)"
                          placeholder="Select Image"
                          drop-placeholder="Drop file here..."
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import _ from "lodash";
export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        title: "",
        status: true,
        image: "",
        description: "",
      },
      image: this.$store.state.base_url + "images/course/default.png",
      overviews: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const overviews = await this.callApi(
        "post",
        "/app/job/placement/overview?page=" + this.search.page,
        this.search
      );
      if (overviews.status == 200) {
        this.overviews = overviews.data.overviews;
      }
    },

    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.title = "";
      this.form.status = true;
      this.form.image = "";
      this.form.description = "";
      this.image = this.$store.state.base_url + "images/course/default.png";
    },

    async edit(id) {
      const overview = await this.callApi(
        "post",
        "/app/job/placement/overview/edit/" + id
      );
      if (overview.status == 200) {
        this.form.id = overview.data.overview.id;
        this.form.title = overview.data.overview.title;
        this.form.description = overview.data.overview.description;
        this.image = this.$store.state.base_url + overview.data.overview.image;
        this.form.status = overview.data.overview.status == true ? true : false;
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.image) {
        fd.append("image", this.form.image, this.form.image.name);
      }

      fd.append("id", this.form.id);
      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append("status", this.form.status);
      axios
        .post("/app/job/placement/overview/store", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "job.placement.overview.access") {
          this.permission.access = true;
        }

        if (permission.slug == "job.placement.overview.create") {
          this.permission.create = true;
        }

        if (permission.slug == "job.placement.overview.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "job.placement.overview.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
